import { CATEGORIES } from '@kijiji/category'
import {
  useGetCategoriesNearYouQuery,
  useGetPopularKeywordsNearYouQuery,
} from '@kijiji/generated/graphql-types'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { type FC, Fragment } from 'react'
import { useTheme } from 'styled-components'

import {
  ITEMS_TO_SHOW,
  PopularNearYouContainer,
  PopularNearYouLink,
  PopularNearYouList,
  PopularNearYouWrapper,
} from '@/components/homepage/popular-near-you/styled'
import { useGetLocation } from '@/hooks/location/useGetLocation'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT, GA_EVENT_LABEL } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Skeleton } from '@/ui/atoms/skeleton'
import { SkeletonContainer } from '@/ui/atoms/skeleton/styled'
import { Spacing } from '@/ui/atoms/spacing'
import { sendToLogger } from '@/utils/sendToLogger'
import { capitalizeString } from '@/utils/string'
import { removeFreeKeywordFromUrl } from '@/utils/url'

const RightArrowIcon = dynamic(() => import('@kijiji/icons/src/icons/Chevron'), { ssr: false })

type PopularItem = {
  label: string
  url: string
}

/**
 * Custom hook to fetch popular keywords near a given location.
 *
 * @param locationId - The ID of the location to fetch popular keywords for.
 * @returns An object containing the query data, loading state, and any errors.
 */
function usePopularNearYouData(locationId: number) {
  const { data, loading, error } = useGetPopularKeywordsNearYouQuery({
    fetchPolicy: 'cache-first',
    variables: {
      searchUrlPopularInput: {
        location: { id: locationId },
        categoryId: CATEGORIES.ROOT_CATEGORY_ID.toString(),
      },
    },
  })

  if (error) {
    sendToLogger('Error fetching popular near you data', {
      fingerprint: ['popular-near-you-error'],
      extra: { error, locationId },
    })
  }

  return { data, loading, error }
}

/**
 * Custom hook to fetch categories near a given location.
 *
 * @param locationId - The ID of the location to fetch categories for.
 * @returns The query result containing category data.
 */
function useCategoriesNearYou(locationId: number) {
  return useGetCategoriesNearYouQuery({
    fetchPolicy: 'cache-first',
    variables: {
      locationId,
    },
  })
}

/**
 * Generates fallback popular items from the menu data.
 */
function getFallbackPopularItems(
  menu: ReturnType<typeof useCategoriesNearYou>['data']
): PopularItem[] {
  return (menu?.menuPrefetch ?? [])
    .filter((item): item is NonNullable<typeof item> => item !== null)
    .map((item) => ({
      label: item.categoryName,
      url: item.seoUrl,
    }))
}

type PopularItemsListProps = {
  readonly items: readonly PopularItem[]
  readonly onItemClick: (item: PopularItem, index: number) => void
}

/**
 * Custom URL logic for popular items.
 */
function getPopularNearYouCustomUrl(label: string, defaultUrl: string): string {
  if (label.toLowerCase() === 'free' || label.toLowerCase() === 'gratuit') {
    return `${removeFreeKeywordFromUrl(defaultUrl, label)}?price-type=free`
  }

  return defaultUrl
}

/**
 * Renders a list of popular items.
 */
function PopularItemsList({ items, onItemClick }: PopularItemsListProps) {
  return (
    <PopularNearYouList>
      {items.map((item, index) => {
        const customUrl = getPopularNearYouCustomUrl(item.label, item.url)

        return (
          <Fragment key={`hp-popular-near-you-${index + 1}`}>
            <li>
              <PopularNearYouLink href={customUrl} onClick={() => onItemClick(item, index)}>
                {capitalizeString(item.label)}
                <RightArrowIcon aria-hidden />
              </PopularNearYouLink>
            </li>
          </Fragment>
        )
      })}
    </PopularNearYouList>
  )
}

export const PopularNearYou: FC = () => {
  const { t } = useTranslation('home')
  const { colors, spacing } = useTheme()
  const { location } = useGetLocation()

  const {
    data: popularNearYouData,
    loading: popularNearYouLoading,
    error: popularNearYouError,
  } = usePopularNearYouData(location.id || 0)
  const { data: categoriesData } = useCategoriesNearYou(location.id)

  // If there's no error, we show the popular keywords from the API
  // Otherwise we show the fallback categories
  // We also limit the number of popular keywords based on ITEMS_TO_SHOW value, even the API returns by default 10 results
  const popularItems = popularNearYouData?.searchUrlsPopular?.length
    ? popularNearYouData.searchUrlsPopular.slice(0, ITEMS_TO_SHOW)
    : getFallbackPopularItems(categoriesData).slice(0, ITEMS_TO_SHOW)

  // Track the event with the keyword, button and position
  const handleItemClick = (item: PopularItem, index: number) => {
    const label = `keyword=${item.label};btn=${GA_EVENT_LABEL.POPULAR_NEAR_YOU};pos=${index + 1}`

    trackEvent({
      action: GA_EVENT.CategorySelected,
      label,
    })
  }

  // We don't show the component if there's an error
  if (popularNearYouError) {
    return null
  }

  return (
    <PopularNearYouContainer $isFullWidth data-testid="popular-kijiji-home">
      <Spacing mBottom={spacing.large}>
        <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
          {t('popular_near_you.title')}
        </HeadlineText>
      </Spacing>
      <PopularNearYouWrapper>
        {popularNearYouLoading ? (
          <ul>
            {Array.from({ length: ITEMS_TO_SHOW }).map((_, index) => (
              <li key={`skeleton-${index + 1}`} data-testid="skeleton">
                <SkeletonContainer>
                  <Skeleton height="2.4rem" width="25rem" bottom="1.5rem" />
                </SkeletonContainer>
              </li>
            ))}
          </ul>
        ) : (
          <PopularItemsList items={popularItems} onItemClick={handleItemClick} />
        )}
      </PopularNearYouWrapper>
    </PopularNearYouContainer>
  )
}
