import {
  type GalleryItem,
  PriceType,
  useGetHomepageGalleryQuery,
} from '@kijiji/generated/graphql-types'
import { useSession } from 'next-auth/react'
import { useTranslation } from 'next-i18next'
import { type FC, type ReactNode, useState } from 'react'

import { getHomepageGalleryVariables } from '@/components/homepage/homepage-gallery/getHomepageGallery'
import { HomepageGalleryHeader } from '@/components/homepage/homepage-gallery/HomepageGalleryHeader'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { LegacyCarousel } from '@/components/shared/legacy-carousel/LegacyCarousel'
import { LoadingGallery } from '@/components/shared/loading-gallery/LoadingGallery'
import {
  type VerticalListingCardProps,
  VerticalListingCard,
} from '@/components/shared/vertical-listing-card/VerticalListingCard'
import { isValidLocation } from '@/domain/location/isValidLocation'
import { isUserAuthenticated } from '@/features/auth/constants/user'
import { useLocale } from '@/hooks/useLocale'
import { mergeFetchMore } from '@/lib/apollo/mergeFetchMore'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { isGalleryItemsType } from '@/types/homepage'
import { Flex } from '@/ui/atoms/flex'
import { isMobileDevice } from '@/utils/userAgent'

export type HomepageGalleryProps = {
  userAgent: string
  locationId: number
  locationName?: string | null
  getSeoUrl: (categoryId: number, index?: number) => string
}

/** Should request data in chunks of 14 items */
const HP_GALLERY_REQUEST_CHUNKS = 14
const SLIDES_PER_DEVICE = { DESKTOP: 7, TABLET: 5.5, MOBILE: 2.5 } as const

export const HomepageGallery: FC<HomepageGalleryProps> = ({
  userAgent = '',
  locationId,
  locationName,
  getSeoUrl,
}) => {
  const [onInitialLoad, setOnInitialLoad] = useState<boolean>(true)
  const { cookieLocale } = useLocale()
  const { t } = useTranslation('home')

  const [hasReachedLastPage, setHasReachedLastPage] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)

  const { status } = useSession()
  /**
   * When logged out, we fetch the data server-side for SEO purposes & skip the loading skeleton so that the links are crawlable
   * When logged in, we fetch the data client-side so need the loading skeleton
   */
  const skipLoadingSkeletionForSSR = isUserAuthenticated(status)

  const fetchVariables = getHomepageGalleryVariables(userAgent, cookieLocale, locationId, page)

  const { data, fetchMore } = useGetHomepageGalleryQuery({
    fetchPolicy: 'cache-first',
    skip: !isValidLocation(locationId),
    variables: fetchVariables,
    onCompleted: ({ homepageGallery }) => {
      const dataCount = homepageGallery?.items?.length
      const isLastPage = !dataCount || dataCount < HP_GALLERY_REQUEST_CHUNKS

      setHasReachedLastPage(isLastPage)

      /**
       * Initial load has been completed when the HP Gallery first loads.
       * After that it shouldn't be set to true again and the loading state won't be re-triggered
       */
      if (onInitialLoad) {
        setOnInitialLoad(false)
      }
    },
  })

  const handleYourAdHereTracking = () => {
    trackEvent({ action: GA_EVENT.PostAdBegin, label: 'btn=hp-glry-ad;' })
  }

  const handleImpressionsTracking = (listingId: number, position: number) => {
    trackEvent({
      action: GA_EVENT.SelectPromotion,
      label: `partnerid=${listingId};position=${position}`,
    })
  }

  const handleLoadMore = async () => {
    if (hasReachedLastPage) return

    await fetchMore({
      variables: {
        ...fetchVariables,
        page: page + 1,
      },
      /** Merge items on each fetch */
      updateQuery(prevResult, { fetchMoreResult }) {
        const prevItems = prevResult.homepageGallery?.items
        const newItems = fetchMoreResult.homepageGallery?.items

        const mergedItems = mergeFetchMore<GalleryItem>({
          prevItems: isGalleryItemsType(prevItems) ? prevItems : [],
          fetchMoreItems: isGalleryItemsType(newItems) ? newItems : [],
        })

        /**
         * Reached the end of the page once there are no more items,
         * or the query returned less items than requested */
        const dataCount = newItems?.length
        const isLastPage = !dataCount || dataCount < HP_GALLERY_REQUEST_CHUNKS

        setHasReachedLastPage(isLastPage)
        !isLastPage && setPage((prev) => prev + 1)

        return {
          __typename: 'Query',
          homepageGallery: { __typename: 'HomepageGallery', items: mergedItems },
        }
      },
    })
  }

  const getPlaceholderSlides = () => {
    const itemsCount = data?.homepageGallery?.items?.length || 0
    const remainingSpaces = !isMobileDevice(userAgent)
      ? itemsCount < SLIDES_PER_DEVICE.DESKTOP
        ? Math.ceil(SLIDES_PER_DEVICE.DESKTOP - itemsCount)
        : 0
      : itemsCount < SLIDES_PER_DEVICE.TABLET
        ? Math.ceil(SLIDES_PER_DEVICE.TABLET - itemsCount)
        : 0

    const props: VerticalListingCardProps = {
      handleClick: handleYourAdHereTracking,
      listingImageSize: 'small',
      seoUrl: t('gallery.your_ad_here.link'),
      title: t('gallery.your_ad_here.label'),
    }

    if (remainingSpaces) {
      return Array.from({ length: remainingSpaces }, (_, index) => (
        <VerticalListingCard key={`hp-gallery-placeholder-${index}`} {...props} />
      ))
    }

    return [<VerticalListingCard key={`hp-gallery-placeholder`} {...props} />]
  }

  let slides: ReactNode[] =
    data?.homepageGallery?.items?.map((item, index) => {
      if (!item) return null

      const position = index + 1
      return (
        <VerticalListingCard
          key={`hp-gallery-${item.id}`}
          listingImage={{ alt: item.imageAltText, src: item.imageUrl }}
          listingImageSize="small"
          seoUrl={item.seoUrl}
          title={item.title}
          price={item.price?.type !== PriceType.Fixed ? undefined : item.price?.amount}
          handleClick={() => handleImpressionsTracking(item.id, position)}
        />
      )
    }) || []

  /**
   * Add placeholder listing at the end of the carousel once the data has reached the last page
   * */
  if (hasReachedLastPage) {
    slides = [...slides, ...getPlaceholderSlides()]
  }

  /** Should not show section if data has loaded, but there is no data to show */
  if (onInitialLoad === false && !data?.homepageGallery?.items?.length) return null

  return (
    <GalleryWrapper data-testid="homepage-gallery-section">
      {onInitialLoad && skipLoadingSkeletionForSSR ? (
        <Flex flexDirection="column" data-testid="hp-gallery-loading">
          <LoadingGallery hasLoadingTitle itemsCount={6} useLegacyLgDesktopBreakpoint />
        </Flex>
      ) : (
        <>
          <HomepageGalleryHeader
            locationId={locationId}
            locationName={locationName}
            getSeoUrl={getSeoUrl}
          />

          <Flex>
            <LegacyCarousel
              callbackOnScroll={{ callbackFn: handleLoadMore, percentage: 50 }}
              dragFree
              name="hp-gallery-carousel"
              shouldShowArrows={[true, false, false]}
              slides={slides}
              slidesToScroll={[2.5, 1.5, 1.5]}
              slidesToShow={[
                SLIDES_PER_DEVICE.DESKTOP,
                SLIDES_PER_DEVICE.TABLET,
                SLIDES_PER_DEVICE.MOBILE,
              ]}
              useLegacyLgDesktopBreakpoint
            />
          </Flex>
        </>
      )}
    </GalleryWrapper>
  )
}
