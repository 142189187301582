import { CATEGORIES } from '@kijiji/category'
import { useGetListingsMostRecentQuery } from '@kijiji/generated/graphql-types'
import { colors, spacing } from '@kijiji/theme'
import { Flex } from '@kijiji/ui'
import { useTranslation } from 'next-i18next'

import { GalleryHeaderWrapper } from '@/components/shared/gallery/GalleryHeaderWrapper'
import { GalleryWrapper } from '@/components/shared/gallery/GalleryWrapper'
import { LegacyCarousel } from '@/components/shared/legacy-carousel/LegacyCarousel'
import { VerticalListingCard } from '@/components/shared/vertical-listing-card/VerticalListingCard'
import { MOST_RECENT_LISTINGS_TO_FETCH } from '@/features/listings-most-recent/constants/constants'
import { trackEvent } from '@/lib/ga'
import { GA_EVENT } from '@/lib/ga/constants/gaEvent'
import { HeadlineText } from '@/ui/atoms/headline-text'
import { Spacing } from '@/ui/atoms/spacing'

type ListingsMostRecentProps = {
  locationId: number
}

export const ListingsMostRecentGallery = ({ locationId }: ListingsMostRecentProps) => {
  const { t } = useTranslation('home')

  const { data } = useGetListingsMostRecentQuery({
    fetchPolicy: 'cache-first',
    variables: {
      by: {
        query: {
          categoryId: CATEGORIES.ROOT_CATEGORY_ID,
          location: {
            id: locationId,
          },
        },
      },
      pagination: {
        limit: MOST_RECENT_LISTINGS_TO_FETCH,
      },
    },
  })

  const handleListingClick = () => {
    trackEvent({ action: GA_EVENT.SelectPromotion, label: 'listings_most_recent' })
  }

  const slides =
    data?.searchResultsPage?.results?.mainListings.map((listing) => {
      const price =
        listing.price?.__typename === 'AutosDealerAmountPrice' ||
        listing.price?.__typename === 'StandardAmountPrice'
          ? listing.price.amount
          : null

      return (
        <VerticalListingCard
          allowFavourite={false}
          key={`listing-${listing.id}`}
          listingImage={{ alt: '', src: listing.imageUrls?.[0] }}
          listingImageSize="regular"
          handleClick={handleListingClick}
          price={price}
          seoUrl={listing.url}
          title={listing.title}
        />
      )
    }) ?? []

  return (
    <GalleryWrapper data-testid="most-recent-listings-section">
      <Spacing mBottom={spacing.default} data-testid="most-recent-gallery-header">
        <GalleryHeaderWrapper justifyContent="space-between" alignItems="center">
          <HeadlineText as="h2" color={colors.grey.primary} size="medium" weight="regular">
            {t('listings_most_recent.title')}
          </HeadlineText>
        </GalleryHeaderWrapper>
      </Spacing>
      <Flex>
        <LegacyCarousel
          name="most-recent-listings"
          shouldShowArrows={[true, false, false]}
          slides={slides}
          slidesToScroll={[2.5, 1.5, 1.5]}
          slidesToShow={[5, 4.2, 2.2]}
          useLegacyLgDesktopBreakpoint
        />
      </Flex>
    </GalleryWrapper>
  )
}
