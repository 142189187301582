import { type ApolloClient, type NormalizedCacheObject } from '@apollo/client'
import {
  type GetHomepageGalleryQuery,
  type GetHomepageGalleryQueryVariables,
  GetHomepageGalleryDocument,
} from '@kijiji/generated/graphql-types'

import { DEVICE_TYPE } from '@/constants/pageSettings'
import { type CookieLocale } from '@/domain/locale'
import { isMobileDevice } from '@/utils/userAgent'

export const HP_GALLERY_REQUEST_CHUNKS = 14

export const getHomepageGalleryVariables = (
  userAgent: string,
  cookieLocale: CookieLocale,
  locationId: number,
  page: number = 0
) => ({
  deviceType: isMobileDevice(userAgent) ? DEVICE_TYPE.MOBILE : DEVICE_TYPE.DESKTOP,
  locale: cookieLocale,
  locationId: locationId,
  numberOfAds: HP_GALLERY_REQUEST_CHUNKS,
  page,
})

export const getHomepageGallery = async ({
  apolloClient,
  variables,
}: {
  apolloClient: ApolloClient<NormalizedCacheObject>
  variables: GetHomepageGalleryQueryVariables
}) => {
  return await apolloClient.query<GetHomepageGalleryQuery, GetHomepageGalleryQueryVariables>({
    query: GetHomepageGalleryDocument,
    variables,
    fetchPolicy: 'cache-first',
  })
}
